<template>
	<div class="box">
		<CustomTable
			ref="table"
			id_table="contacts_horse"
			:busy.sync="table_busy"
			primaryKey="contact_id"
			:hide_if_empty="true"
			:items="formattedContactHorses"
			:hrefsRoutes="config_table_hrefs"
			:base-filters="filters"
		/>
	</div>
</template>

<script type="text/javascript">
import Contact from "@/mixins/Contact.js"
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "ContactHorse",
	mixins: [Contact, Navigation],
	props: {
        contact: { type: Object, default: null}
    },
	data () {
		return {
			table_busy: true,
			contact_horses: [],
			config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'HorseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				}
			}
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.loadTiers()
		},
		async loadTiers() {
			this.table_busy = true
			this.contact_horses = await this.getHorsesByContact(this.contact.contact_id)
			this.table_busy = false
		},
	},
	computed: {
		formattedContactHorses: function() {
			let contactsHorsesFormated = []
			if(!this.contact_horses) {
				return []
			}

			const copy_temp = this.deppCloneObj(this.contact_horses)
			for(let i = 0; i < copy_temp.length; i++) {
				const temp = {...copy_temp[i]}
				temp.fonction = this.getArrayObjProperty(temp.fonctions, "fonction_label")
				temp.fonction = temp.fonction.map((v) => {
					return this.getTrad(v)
				})
				temp.fonction = temp.fonction.join('<br>')

				contactsHorsesFormated.push(temp);
			}

			return contactsHorsesFormated
		},
		filters() {
			let filters = {
				horse_not_inactive: {
					column: 'horse.horse_inactive',
					operator: 'isEqualTo',
					value: 0
				}
			}

			return filters
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
